<template>
  <div class="container">
    <header>
      <img :src="require('./assets/images/logo.png')" alt="">
      <img :src="require('./assets/images/slogan.png')" alt="">
      <img :src="require('./assets/images/header-open-app.png')" alt="" @click="download">
    </header>
    <img :src="require('./assets/images/btn-open-app.png')" alt="" @click="download">

    <!-- 引导默认浏览器打开网页 -->
    <guide-app v-model:showGuide="showGuide" />
  </div>
</template>

<script>
import GuideApp from "@/components/GuideApp.vue";
export default {
  components: { GuideApp },
  data() {
    return {
      showGuide: false,
      isWechatBrowser: false,
      isIos: false,
      isAndroid: false,
    }
  },
  created() {
    const { userAgent } = window.navigator;
    this.isWechatBrowser = userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    this.isIos = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.isAndroid = userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;

    /* 默认打开引导浏览器提示的场景【设备：安卓/IOS，环境：微信浏览器】 */
    this.showGuide = this.isWechatBrowser;
  },
  methods: {
    download() {
      /**
       * 下载规则
       * 微信内:
       * 	[ios] 弹出打开默认浏览器的弹框，引导使用默认浏览器打开网页
       * 	[android] 弹出打开默认浏览器的弹框，引导使用默认浏览器打开网页
       * 微信外:
       * 	[ios] 不弹打开默认浏览器的弹框，点击下载，打开 https://apps.apple.com/app/%E7%B2%97%E9%97%A8/id1593823623
       * 	[android] 不弹打开默认浏览器的弹框，点击下载，打开 market://details?id=tech.xiaoxiang.emily
       */
      if(this.isWechatBrowser) this.showGuide = true;
      else {
        location.href = this.isIos
          ? "https://apps.apple.com/app/%E7%B2%97%E9%97%A8/id1593823623"
          : "market://details?id=tech.xiaoxiang.emily";
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

$screenHeight: 216.533vw;

* {
  -webkit-tap-highlight-color: transparent;
}

.container {
  width: 100%;
  height: 100vh;
  min-height: $screenHeight;
  background: #222 url("./assets/images/screen-cover.png") no-repeat center top / contain;

  > header {
    @include row(flex-start, center);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(144);
    background-color: #fff;

    > img {
      &:first-child {
        width: px2rem(96);
        height: px2rem(96);
        margin-left: px2rem(32);
      }

      &:nth-child(2) {
        width: px2rem(172);
        height: px2rem(92);
        margin-left: px2rem(24);
      }

      &:last-child {
        width: px2rem(160);
        height: px2rem(64);
        margin-left: auto;
        margin-right: px2rem(32);
      }
    }

    + img {
      position: fixed;
      left: 50%;
      bottom: px2rem(64);
      transform: translateX(-50%);
      width: px2rem(480);
      height: px2rem(80);
    }
  }

}
</style>
